.content {
  // background: url('/images/planbowl/create_top_bottom_bg.webp') 100% 100% no-repeat;
  background: url('https://res-front.pumpsoul.com/pb-prod/image/create_top_bottom_bg.webp') 100% 100% no-repeat;

  background-size: 100% 40%;
  background-position: 0 -10px;
  padding: 40px 48px;
  border-radius: 10px;
  overflow: hidden;

  h3 {
    font-size: 26px;
    margin-bottom: 24px;
  }

  .synthesisReport {
    margin-top: 12px;
    .title {
      font-size: 16px;

      margin-bottom: 20px;
    }

    .reportList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      color: #fff;

      .reportItem {
        position: relative;
        display: flex;
        align-items: flex-end;
        height: 120px;
        // background: url('/images/share/synthesisReport-bg.webp');
        background: url('https://res-front.pumpsoul.com/pb-prod/image/synthesisReport-bg.webp');
        background-position: center;
        background-size: 100% 100%;
        padding: 16px 21px;
        border-radius: 5px;
        overflow: hidden;
        .reportName {
          font-size: 16px;
        }
        .model {
          display: none;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          .btn {
            padding: 9px;
            background: var(--app-primary-color);
            color: #fff;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        &:hover {
          .reportName {
            display: none;
          }
          .model {
            display: flex;
          }
        }
      }

      .reporItemAll {
        display: flex;
        align-items: flex-end;
        height: 120px;
        // background: url('/images/share/synthesisReport-bg.webp');
        background: url('https://res-front.pumpsoul.com/pb-prod/image/synthesisReport-bg.webp');
        background-position: center;
        background-size: 100% 100%;
        font-weight: 600;
        text-wrap: wrap;
        padding-top: 47px;
        padding-left: 18px;
        padding-bottom: 18px;
        cursor: pointer;
        .reportTitle {
          padding-right: 70px;
        }
      }
    }
    .reportData {
      grid-template-rows: repeat(2, 1fr);
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    h3 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .synthesisReport {
      .title {
        margin-top: 12px;
      }
      .reportList {
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        margin-top: 12px;
        .reporItemAll {
          padding-top: 14px;
          padding-left: 16px;
          font-size: 16px;
          padding-bottom: 29px;
        }
        .reportItem {
          height: 100px;
        }
      }
      .reportData {
        grid-template-rows: repeat(3, 1fr);
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .content {
    background-size: 100% 50%;
    padding: 37px 15px;
    background-position: 100% -10px;
    h3 {
      font-size: 24px;
      margin-bottom: 12px;
      padding-right: 24px;
      font-weight: 700;
    }

    .synthesisReport {
      .title {
        margin-top: 12px;
      }
      .reportList {
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        margin-top: 12px;
        .reporItemAll {
          width: 200px;
          height: 96px;
          padding: 14px 0 16px 14px;
          font-size: 16px;
          .reportTitle {
            padding-right: 40px;
          }
        }
        .reportItem {
          //   width: 157px;
          padding: 10px 12px;
          height: 76px;
          .reportName {
            font-size: 14px;
          }
        }
      }
      .reportData {
        grid-template-rows: repeat(3, 1fr);
        margin-bottom: 20px;
      }
    }
  }
}

